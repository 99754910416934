import React from "react"
import styles from "./ref.module.scss"
import Menubar from "../components/menubar/menubar"
import Footer from "../components/footer/footer"
import TopImage from "../img/ref_1280.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
export default props => (
  <div className={styles.refContainer}>
    <div className={styles.leftE}></div>
    <div className={styles.centerSite}>
      <Menubar />
      <div className={styles.imageTopRefContainer}>
        <img src={TopImage} alt="oyma" className={styles.imageTopRef} />
        <div className={styles.verticalLineWhiteRef}>
          <div className={styles.socialMediaIcons}>
            <a
              href="https://www.facebook.com/atiaahsap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size="1x"
                className={styles.ffIcon}
              />
            </a>
            <br />
            <a
              href="https://www.instagram.com/atiaahsap/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="1x"
                className={styles.igIcon}
              />
            </a>
          </div>
        </div>
        <span className={styles.topSpanRefInt}>INTERIOR</span>
        <span className={styles.topSpanRef}>REFERANSLARIMIZ</span>
        <span className={styles.topSpanRefBottom}>
          REFERANSLARIMIZDAN <span style={{ color: "#977851" }}>BAZILARI</span>
        </span>
      </div>
      <div className={styles.refList}>
        <div className={styles.leftList}>
          <ul>
            <li>ALFEN İNŞAAT</li>
            <li>ARC İNŞAAT</li>
            <li>ATATEPE İNŞAAT </li>
            <li>CORIO TURKİYE</li>
            <li>DOĞRUER İNŞAAT</li>
            <li>GÜRBAĞ İNŞAAT</li>
            <li>ÖZOĞUZ İNŞAAT</li>
            <li>RG İNŞAAT</li>
            <li>TEZELLER İNŞAAT</li>
            <li>YEŞİLYURT DEMİR ÇELİK</li>
            <li>
              4. ANA JET ÜSSÜ KOMUTANLIĞI <br />
              AKINCI - ANKARA
            </li>
            <li>İSPANYA TİCARET MÜSTEŞARLIĞI</li>
            <li>JANDARMA GENEL KOMUTANLIĞI</li>
            <li>K.İ.K</li>
            <li>KARS HAVALİMANI</li>
            <li>ÖZEL ALTIN EĞİTİM OKULLARI</li>
            <li>SAMSUN HAVALİMANI</li>
            <li>
              T.C. ANKARA VALİLİĞİ <br />
              İL ÖZEL İDARE
            </li>
            <li>T.C. İÇİŞLERİ BAKANLIĞI</li>
            <li>T.C. KIRKLARELİ VALİLİĞİ</li>
            <li>TAI & TUSAŞ İ.K.</li>
            <li>TRABZON HAVALİMANI</li>
            <li>TSK GÜÇLENDİRME VAKFI</li>
            <li>312 MİMARLIK</li>
            <li>ABDULLAH GENCASLAN </li>
            <li>ALTAY & ALTAY MİMARLIK </li>
            <li>D'ARCHITECTs DEMAY MİMARLIK</li>
            <li>ÖNCÜOĞLU + ACP MİMARLIK</li>
            <li>TÜMER ONARAN MİMARLIK</li>
          </ul>
        </div>
        <div className={styles.rightList}>
          <ul>
            <li>U DESIGN MİMARLIK</li>
            <li>365 AVM ANKARA</li>
            <li>DİYARBAKIR 3M MİGROS</li>
            <li>MALLTEPE PARK AVM</li>
            <li>OPTİMUM AVM ANKARA</li>
            <li>TURKMALL AVM ŞANLIURFA</li>
            <li>TURKMALL AVM YOZGAT</li>
            <li>GÖKDELEN KULE - SAMSUN</li>
            <li>NOVA TOWER ÇUKURAMBAR - ANKARA</li>
            <li>SAMTOWER - SAMSUN</li>
            <li>
              SEDA APARTMANI <br />
              ÇUKURAMBAR - ANKARA
            </li>
            <li>TWIN TOWER - SAMSUN</li>
            <li>VADİ PRESTİJ SİTESİ GOP - ANKARA</li>
            <li>VIVENDI ÇAYYOLU - ANKARA</li>
            <li>1071 ANKARA - USTA İNŞAAT</li>
            <li>
              H.BAĞIBAN KONUTU <br />
              ANGORA EVLERİ - ANKARA
            </li>
            <li>İ.MENEKŞE KONUTU ÇANKAYA - ANKARA</li>
            <li>M.AKSU KONUTU İNCEK - ANKARA</li>
            <li>M.AYGAR KONUTU BEYTEPE - ANKARA</li>
            <li>M.BAŞTUĞ KONUTU İSKENDERUN - HATAY</li>
            <li>
              M.DEMİRBAŞ KONUTU SİNPAŞ <br />
              İNCEK LIFE - ANKARA
            </li>
            <li>M.KUMBUZOĞLU KONUTU GÖLBAŞI - ANKARA</li>
            <li>Ö.ARPACI KONUTU ÇANKAYA - ANKARA</li>
            <li>
              S.DÜVEROĞLU KONUTU <br />
              YEŞİL BARIŞ SİTESİ - ANKARA
            </li>
            <li>S.SOYLU KONUTU BAŞAKŞEHİR - İSTANBUL</li>
            <li>T.YEŞİLYURT KONUTU İSKENDERUN - HATAY</li>
            <li>U.DEMİRKILIÇ KONUTU ANGORA EVLERİ - ANKARA</li>
            <li>ÖZBERK İNŞAAT BURGAZ 10 VİLLA - MUĞLA</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
    <div className={styles.rightE}></div>
  </div>
)
